import React from "react";
import TeamTitle from "../../components/title/TeamTitle";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import Protected from "../../components/protected/Protected";
import MultiRequestBody from "../../components/request/multiRequest/MultiRequestBody";
import MultiRequestFooter from "../../components/request/multiRequest/MultiRequestFooter";

class MultiRequestContainer extends React.PureComponent {
  render() {
    return (
      <div>
        <TeamTitle disabled={true} />
        <CardContainer>
          <CardHeader>Request Tickets</CardHeader>
          <CardBody>
            <MultiRequestBody />
          </CardBody>
          <CardFooter>
            <MultiRequestFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default Protected(MultiRequestContainer);
