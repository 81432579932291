import React from "react";
import _ from "lodash";
import { DataTable } from "best-common-react";
import { inject, observer } from "mobx-react";

class SelectContactTable extends React.Component {
  constructor(props) {
    super(props);
    const cols = [
      {
        key: "firstName",
        name: "First Name",
        sortable: true
      },
      {
        key: "lastName",
        name: "Last Name",
        sortable: true
      },
      {
        key: "relation",
        name: "Relation",
        sortable: true
      },
      {
        key: "email",
        name: "Email",
        sortable: true
      },
      {
        key: "phone",
        name: "Phone",
        sortable: true
      }
    ];
    this.state = { data: props.data, sortedData: props.data, cols: cols, selectedContact: null };
  }

  onGridSort = (sortColumn, sortDirection) => {
    if (sortDirection === "NONE") {
      this.setState({ sortedData: this.state.data });
    } else {
      let data = _.sortBy(this.state.data, sortColumn);
      if (sortDirection === "ASC") {
        this.setState({ sortedData: data });
      } else if (sortDirection === "DESC") {
        this.setState({ sortedData: data.reverse() });
      }
    }
  };

  render() {
    const { contactStore } = this.props;
    return (
      <div className="mt-4">
        <h4>Contacts</h4>
        <DataTable
          id="selectContactTable"
          data={contactStore.displayContacts}
          columns={this.state.cols}
          sortFunc={this.onGridSort}
          height={400}
          rowSelection={{
            showCheckbox: true,
            selectBy: {
              isSelectedKey: "isSelected"
            },
            onRowsSelected: rows => contactStore.handleContactSelect(rows[0]),
            onRowsDeselected: () => contactStore.handleContactDeselect(),
            enableShiftSelect: false,
            canSelect: true
          }}
          selectAll={false}
        />
      </div>
    );
  }
}

export default inject("rootStore")(observer(SelectContactTable));
