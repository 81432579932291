import React from "react";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton,
  BestLabel,
  Icon,
  SearchBox
} from "best-common-react";
import { inject, observer } from "mobx-react";
import SelectContactTable from "./SelectContactTable";
import styled from "styled-components";

const ClearableInput = styled.div.attrs({ className: "btn-group" })`
  width: 100%;
`;

const ClearUserSearch = styled.span`
  position: absolute;
  right: 6px;
  top: 8px;
`;

class SelectContactModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.contactStore = props.contactStore;
  }

  render() {
    return (
      <div>
        <Modal
          show={true}
          onClose={() => {
            this.contactStore.setShowSelectContactModal(false);
          }}
          size="lg"
        >
          <ModalHeaderWithTitleAndClose
            title="Select Contact"
            close={() => this.contactStore.setShowSelectContactModal(false)}
          />
          <ModalBody>
            <div className="form-group">
              <BestLabel>Search Contacts</BestLabel>
              <ClearableInput>
                <SearchBox
                  id="search-box"
                  placeholder="Search contacts..."
                  value={this.contactStore.searchTerm}
                  onChange={event => this.contactStore.setSearchTerm(event.target.value)}
                />
                {this.contactStore.searchTerm.length > 0 ? (
                  <ClearUserSearch>
                    <Icon iconName="fa-times-circle" onClick={() => this.contactStore.clearSearchTerm()} />
                  </ClearUserSearch>
                ) : null}
              </ClearableInput>
            </div>
            <div className="form-group">
              <SelectContactTable contactStore={this.contactStore} />
            </div>
          </ModalBody>
          <ModalFooter>
            <div>
              <PrimaryButton onClick={() => this.contactStore.selectContact()}>Select</PrimaryButton>
              <LightButton onClick={() => this.contactStore.setShowSelectContactModal(false)}>Cancel</LightButton>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default inject("rootStore")(observer(SelectContactModal));
