import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { components } from "react-select";
import { BestLabel, BestSelect, ButtonLink, Input, RequiredInputWithLabel, RequiredIndicator } from "best-common-react";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import NewContactModal from "../contacts/NewContactModal";
import SelectContactModal from "../contacts/SelectContactModal";
import { CommonConstants } from "../../constants/CommonConstants";

const CommentInput = styled.textarea.attrs({ maxLength: "150", id: "comment-text-area" })`
  height: 5em;
`;

const SectionOption = ({ data, ...rest }) => (
  <components.Option {...rest}>
    {data && data.value && (
      <div className="d-flex justify-content-between">
        <div>
          {data.value.sectionNumber} @ ${data.value.price}
        </div>
        <div>{data.value.left} left</div>
      </div>
    )}
  </components.Option>
);

const SectionValue = ({ data, ...rest }) => (
  <components.ValueContainer {...rest}>
    {data && data.value && (
      <div>
        <span className="mr-3">
          {data.value.sectionNumber} @ ${data.value.price}
        </span>
        <span>{data.value.left} left</span>
      </div>
    )}
  </components.ValueContainer>
);

class TicketRequestFilters extends React.Component {
  render() {
    const { requestStore, commonStore, authStore, contactStore } = this.props.rootStore;
    const quantityLabel =
      requestStore.allotmentRemaining === 1
        ? requestStore.allotmentRemaining + " ticket remaining"
        : requestStore.allotmentRemaining + " tickets remaining";
    return (
      <div>
        {!!contactStore.showNewContactModal && (
          <NewContactModal requestStore={requestStore} contactStore={contactStore} />
        )}
        {!!contactStore.showSelectContactModal && <SelectContactModal contactStore={contactStore} />}
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div
                hidden={
                  !authStore.isAdmin &&
                  !(authStore.isBoxOfficeAdmin && commonStore.currentOrgId === CommonConstants.KEYS.UMP_ID)
                }
                className="col col-lg-4 col-12 form-group"
              >
                <BestLabel htmlFor="on-behalf-of">Request On Behalf Of</BestLabel>
                <BestSelect
                  id="on-behalf-of"
                  options={requestStore.userDropdown}
                  value={requestStore.selectedUser}
                  onChange={item => requestStore.setUser(item.value)}
                  isSearchable={true}
                />
              </div>
              <div className="col-lg-4 col-md-6 col-12 form-group">
                <div className="d-flex justify-content-between align-items-end">
                  <BestLabel htmlFor="firstName">
                    First Name <RequiredIndicator />
                  </BestLabel>
                  <ButtonLink
                    style={{ fontSize: "0.875rem", padding: "0px" }}
                    onClick={() => contactStore.setShowSelectContactModal(true)}
                  >
                    Select Contact
                  </ButtonLink>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  id="firstName"
                  value={requestStore.firstName}
                  onChange={firstName => requestStore.setFirstName(firstName.target.value)}
                />
              </div>
              <div className="col-lg-4 col-md-6 col-12 form-group">
                <RequiredInputWithLabel name="lastName" label="Last Name" htmlFor="lastName">
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    id="lastName"
                    value={requestStore.lastName}
                    onChange={lastName => requestStore.setLastName(lastName.target.value)}
                  />
                </RequiredInputWithLabel>
              </div>
            </div>
            <div className="secondRow row">
              <div className="col-lg-4 col-12 form-group">
                <RequiredInputWithLabel name="relation" label="Relation" htmlFor="friends-family">
                  <BestSelect
                    id="friends-family"
                    options={commonStore.friendsAndFamilyDropdown}
                    value={requestStore.selectedRelation}
                    onChange={item => requestStore.setRelation(item)}
                  />
                </RequiredInputWithLabel>
              </div>
              <div className="col-lg-4 col-12 form-group">
                <RequiredInputWithLabel name="availableSections" label="Available Sections" htmlFor="sections">
                  <BestSelect
                    id="sections"
                    options={requestStore.sectionInventoryOptions}
                    value={requestStore.section}
                    onChange={item => requestStore.setSelectedSection(item)}
                    components={{ Option: SectionOption, SingleValue: SectionValue }}
                    placeholder=""
                  />
                </RequiredInputWithLabel>
              </div>
              <div className="col-lg-4 col-12 form-group">
                <RequiredInputWithLabel name="quantity" label="Quantity" rightLabel={quantityLabel} htmlFor="allotment">
                  <BestSelect
                    id="allotment"
                    options={requestStore.allotmentDropdown}
                    value={requestStore.quantity}
                    onChange={item => requestStore.setQuantity(item)}
                    placeholder=""
                  />
                </RequiredInputWithLabel>
              </div>
            </div>
            <div className="row">
              {commonStore.useHHDropdown ? (
                <div className="col-lg-4 col-12 form-group">
                  <RequiredInputWithLabel name="householdType" label="Household Type" htmlFor="household-type">
                    <BestSelect
                      id="household-type"
                      options={commonStore.householdOptions}
                      value={requestStore.household}
                      onChange={item => requestStore.setHousehold(item)}
                    />
                  </RequiredInputWithLabel>
                </div>
              ) : null}
              <div className="col-lg-4 col-12 form-group">
                <RequiredInputWithLabel name="pickupLocation" label="Pickup Location" htmlFor="pickup-location">
                  <BestSelect
                    id="pickup-location"
                    options={requestStore.pickupLocationDropdown}
                    value={requestStore.pickupLocation}
                    onChange={item => requestStore.setPickupLocation(item)}
                  />
                </RequiredInputWithLabel>
              </div>
              {requestStore.pickupLocation && requestStore.pickupLocation.value.digitalDelivery ? (
                <React.Fragment>
                  <div className="col-lg-4 col-12 form-group">
                    {requestStore.pickupLocation.value.requireEmail ? (
                      <RequiredInputWithLabel name="deliveryEmail" label="Email" htmlFor="delivery-email">
                        <Input
                          type="email"
                          className="form-control"
                          id="delivery-email"
                          value={requestStore.deliveryEmail}
                          onChange={deliveryEmail => requestStore.setDeliveryEmail(deliveryEmail.target.value)}
                        />
                      </RequiredInputWithLabel>
                    ) : null}
                  </div>
                  <div className="col-lg-4 col-12 form-group">
                    {requestStore.pickupLocation.value.requireMobilePhone ? (
                      <RequiredInputWithLabel
                        name="deliveryMobilePhone"
                        label="Mobile Phone"
                        htmlFor="intl-tel-input-2"
                      >
                        <IntlTelInput
                          id="intl-tel-input-2"
                          containerClassName="allow-dropdown intl-tel-input d-flex"
                          placeholder=""
                          inputClassName={
                            "form-control " +
                            (requestStore.validPhone && requestStore.deliveryMobilePhone ? "" : "is-invalid")
                          }
                          defaultCountry={requestStore.countryCode}
                          onlyCountries={["us", "ca"]}
                          onPhoneNumberChange={(valid, value, code, extra) => {
                            requestStore.setDeliveryMobilePhone(value, valid, code);
                          }}
                          onSelectFlag={(value, code, ignore, isValid) => {
                            requestStore.setDeliveryMobilePhone(value, isValid, code);
                          }}
                          value={requestStore.deliveryMobilePhone}
                        />
                      </RequiredInputWithLabel>
                    ) : null}
                  </div>
                </React.Fragment>
              ) : null}
              {requestStore.pickupLocation && requestStore.pickupLocation.value.allowEitherContactMethod ? (
                <React.Fragment>
                  <div className="col-12 pb-2">Please enter a valid e-mail address or USA/Canada phone number.</div>
                  <div className="col-lg-4 col-12 form-group">
                    <BestLabel htmlFor="delivery-email">Email</BestLabel>
                    <Input
                      type="email"
                      className="form-control"
                      id="delivery-email"
                      value={requestStore.deliveryEmail}
                      onChange={deliveryEmail => requestStore.setDeliveryEmail(deliveryEmail.target.value)}
                    />
                  </div>
                  <div className="col-lg-4 col-12 form-group">
                    <BestLabel htmlFor="intl-tel-input-1">Mobile Phone</BestLabel>
                    <IntlTelInput
                      id="intl-tel-input-1"
                      containerClassName="allow-dropdown intl-tel-input d-flex"
                      placeholder="USA/Canada Phone Numbers Only"
                      inputClassName={
                        "form-control " +
                        (requestStore.validPhone || !requestStore.deliveryMobilePhone ? "" : "is-invalid")
                      }
                      defaultCountry={requestStore.countryCode}
                      onlyCountries={["us", "ca"]}
                      onPhoneNumberChange={(valid, value, code, extra) => {
                        requestStore.setDeliveryMobilePhone(value, valid, code);
                      }}
                      onSelectFlag={(value, code, ignore, isValid) => {
                        requestStore.setDeliveryMobilePhone(value, isValid, code);
                      }}
                      value={requestStore.deliveryMobilePhone}
                    />
                  </div>
                </React.Fragment>
              ) : null}
            </div>
            <div className="row justify-content-end">
              <div className="text-right mr-4">
                <ButtonLink
                  style={{ fontSize: "0.875rem", padding: "0px" }}
                  onClick={() => contactStore.setShowNewContactModal(true)}
                >
                  Save Contact
                </ButtonLink>
              </div>
            </div>
            <div className="row">
              <div className="col-12 form-group">
                <BestLabel htmlFor="comments">Comments</BestLabel>
                <CommentInput
                  className="form-control"
                  name="comments"
                  id="comments"
                  value={requestStore.comments}
                  onChange={comment => requestStore.setComments(comment.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(TicketRequestFilters));
