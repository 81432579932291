import React from "react";
import { inject, observer } from "mobx-react";
import {
  BestLabel,
  BestSelect,
  Input,
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton,
  RequiredIndicator,
  RequiredInputWithLabel
} from "best-common-react";
import IntlTelInput from "react-intl-tel-input";

class NewContactModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  render() {
    const { commonStore } = this.props.rootStore;
    const { requestStore, contactStore } = this.props;

    return (
      <div>
        <Modal
          show={true}
          onClose={() => {
            this.setState({ show: false });
          }}
          size="lg"
        >
          <ModalHeaderWithTitleAndClose
            title="Create New Contact"
            close={() => {
              contactStore.setShowNewContactModal(false);
            }}
          />
          <ModalBody>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 form-group">
                <div className="d-flex justify-content-between align-items-end">
                  <BestLabel>
                    First Name <RequiredIndicator />
                  </BestLabel>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  id="firstName"
                  value={requestStore.firstName}
                  onChange={firstName => {
                    requestStore.setFirstName(firstName.target.value);
                    contactStore.updateCanSave();
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6 col-12 form-group">
                <RequiredInputWithLabel name="lastName" label="Last Name">
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    id="lastName"
                    value={requestStore.lastName}
                    onChange={lastName => {
                      requestStore.setLastName(lastName.target.value);
                      contactStore.updateCanSave();
                    }}
                  />
                </RequiredInputWithLabel>
              </div>
              <div className="col-lg-4 col-12 form-group">
                <RequiredInputWithLabel name="relation" label="Relation">
                  <BestSelect
                    id="friends-family"
                    options={commonStore.friendsAndFamilyDropdown}
                    value={requestStore.selectedRelation}
                    onChange={item => {
                      requestStore.setRelation(item);
                      contactStore.updateCanSave();
                    }}
                  />
                </RequiredInputWithLabel>
              </div>
            </div>
            <div className="secondRow row">
              <React.Fragment>
                <div className="col-12 pb-2">Please enter a valid e-mail address or USA/Canada phone number.</div>
                <div className="col-lg-4 col-12 form-group">
                  <BestLabel>Email</BestLabel>
                  <Input
                    type="email"
                    className="form-control"
                    id="deliveryEmail"
                    value={requestStore.deliveryEmail}
                    onChange={deliveryEmail => {
                      requestStore.setDeliveryEmail(deliveryEmail.target.value);
                      contactStore.updateCanSave();
                    }}
                  />
                </div>
                <div className="col-lg-4 col-12 form-group">
                  <BestLabel>Mobile Phone</BestLabel>
                  <IntlTelInput
                    containerClassName="allow-dropdown intl-tel-input d-flex"
                    placeholder="USA/Canada Phone Numbers Only"
                    inputClassName={
                      "form-control " +
                      (requestStore.validPhone || !requestStore.deliveryMobilePhone ? "" : "is-invalid")
                    }
                    defaultCountry={requestStore.countryCode}
                    preferredCountries={["us", "ca"]}
                    onlyCountries={["us", "ca"]}
                    onPhoneNumberChange={(valid, value, code, extra) => {
                      requestStore.setDeliveryMobilePhone(value, valid, code);
                      contactStore.updateCanSave();
                    }}
                    onSelectFlag={(value, code, ignore, isValid) => {
                      requestStore.setDeliveryMobilePhone(value, isValid, code);
                      contactStore.updateCanSave();
                    }}
                    value={requestStore.deliveryMobilePhone}
                  />
                </div>
              </React.Fragment>
            </div>
          </ModalBody>
          <ModalFooter>
            <div>
              <PrimaryButton onClick={() => contactStore.saveContact()} disabled={!contactStore.canSaveContact}>
                Confirm
              </PrimaryButton>
              <LightButton onClick={() => contactStore.setShowNewContactModal(false)}>Cancel</LightButton>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default inject("rootStore")(observer(NewContactModal));
