import { action, autorun, computed, decorate, extendObservable, reaction } from "mobx";
import _ from "lodash";
import convertUser from "./ConvertUser";
import createUserDropdown from "./CreateUserDropdown";
import validate from "validate.js";
import { CommonConstants } from "../../constants/CommonConstants";

class RequestSidebarStore {
  constructor(authStore, commonStore, routerStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.routerStore = routerStore;

    this.defaults = {
      selectedGames: [],
      showSidebar: false
    };

    extendObservable(this, {
      selectedGames: this.defaults["selectedGames"],
      showSidebar: this.defaults["showSidebar"],

      addSelectedGame: action(game => {
        if (!this.selectedGames.map(game => game.gamePk).includes(game.gamePk)) {
          this.selectedGames.push(game);
        } else {
          this.selectedGames = this.selectedGames.filter(g => g !== game);
        }
      }),
      resetStore: action(() => {
        this.selectedGames = this.defaults["selectedGames"];
        this.showSidebar = this.defaults["showSidebar"];
      }),
      createRequests: action(() => {
        this.routerStore.history.push(
          "/events/requests?gamePks=" + this.selectedGames.map(game => game.gamePk).join(",")
        );
        this.resetStore();
      }),
      cancel: action(() => {
        this.resetStore();
      })
    });

    reaction(
      () => commonStore.selectedOrg,
      () => {
        this.resetStore();
      }
    );
  }
}

export default RequestSidebarStore;
