import React from "react";
import { inject, observer } from "mobx-react";
import MultiRequestFilters from "./MultiRequestFilters";
import queryString from "query-string";

class MultiRequestBody extends React.Component {
  render() {
    const { multiRequestStore, routerStore } = this.props.rootStore;
    if (routerStore.isMultiRequestTab) {
      multiRequestStore.setGamePks(
        queryString
          .parse(routerStore.location.search)
          .gamePks.split(",")
          .filter(v => v.length > 0)
      );
    }
    return <MultiRequestFilters />;
  }
}

export default inject("rootStore")(observer(MultiRequestBody));
