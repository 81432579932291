import React from "react";
import { inject, observer } from "mobx-react";
import TicketEvent from "./TicketEvent";

class TicketEventsList extends React.Component {
  render() {
    const { eventsStore } = this.props.rootStore;

    return eventsStore.currentEvents.map(game => {
      return (
        <TicketEvent
          rootStore={this.props.rootStore}
          game={game}
          key={game.gamePk}
          displayCountdown={eventsStore.displayCountdown}
          onIconClick={() => eventsStore.displayPopover(game)}
        />
      );
    });
  }
}

export default inject("rootStore")(observer(TicketEventsList));
